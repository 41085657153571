
import React, { useState } from 'react'
import {  InputAdornment, TextField, Button, Avatar, Box, Container, AppBar, Toolbar } from '@material-ui/core'
import { AccountCircle, Send, VpnKey } from '@material-ui/icons'

import { useHistory } from 'react-router'
import { LockOutlined } from '@material-ui/icons';
import { useQueryParam, StringParam } from 'use-query-params';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { useFormik } from "formik";
import { Helmet } from 'react-helmet';
import { useLoginStyles } from '../styles/loginStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import showPassword from '../images/show-password.svg';
import hidePassword from '../images/hide-password.svg';
import { loadGridChangePassword } from '../helpers/loadGrid';

const ResetPassword = () => {
    let history = useHistory();
    const [ code ] = useQueryParam('code', StringParam);
    const classes = useLoginStyles();
    const [visible, setVisible] = useState(false);
    const [visiblePC, setVisiblePC] = useState(false);
    const { data } = loadGridChangePassword();

    const validationSchema = yup.object().shape({
        password: yup.string().required("La contraseña es requerida").min(8, 'La contraseña debe tener una longitud mínima de 8 caracteres'),
        passwordConfirm: yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
            [yup.ref("password")],
            "Ambas contraseñas deben ser iguales"
            )
        }).required('La contraseña es requerida').min(8, 'La contraseña debe tener una longitud mínima de 8 caracteres')
      });

      const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirm: ''
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            const { password, passwordConfirm } = values
            let params = {
                code,
                password,
                passwordConfirmation: passwordConfirm
            }
            await axios.post(process.env.STRAPI_URL + '/auth/reset-password', params)
            .then( () => {
                Swal.fire('Success', 'Contraseña actualizada', 'success');
                history.push('/login');
                return;
            })
            .catch( (e) => {
                Swal.fire('Error', 'Las contraseñas no coinciden', 'error');
                return;
            })
        }
    });

    
  

    const handleBack = e => {
        history.push('/login')
    }

    return (
        <>
            <div style={{ marginTop: '5rem'}}/>
            <Helmet>
                <title>Reseteo Contraseña | Xplora</title>
            </Helmet>
            <AppBar
                elevation={4}
            >
                <Toolbar sx={{ height: 80 }} variant='dense'>
                </Toolbar>
            </AppBar>
            <Box
                sx={{
                backgroundColor: 'background.default',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                }}

            >
                <Container maxWidth="sm">
                    <Avatar sx={{ bgcolor: 'secondary.main' }}>
                        <LockOutlined />
                    </Avatar>
                    <form onSubmit={formik.handleSubmit}>
                        {
                            data.map( element => {
                                return(
                                    <TextField
                                        fullWidth
                                        label={ element.label }
                                        margin="normal"
                                        name={ element.name }
                                        type={ element.name === 'password' ? (visible ? 'text' : 'password') : ( visiblePC ? 'text' : 'password') }
                                        variant="outlined"
                                        value={formik.values[element.name]}
                                        onBlur={ formik.handleBlur }
                                        onChange={formik.handleChange}
                                        error={formik.touched[element.name] && Boolean(formik.errors[element.name])}
                                        helperText={formik.touched[element.name] && formik.errors[element.name]}
                                        disabled={ formik.isSubmitting }
                                        InputProps={{
                                            startAdornment:(
                                                <InputAdornment position='start'>
                                                    <VpnKey />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                element.name === 'password'
                                                ?
                                                <InputAdornment position='end'  onClick={ () => setVisible(!visible)}>
                                                    <img 
                                                        src={ visible ? showPassword : hidePassword}
                                                        alt='eye-password'
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </InputAdornment>
                                                :
                                                <InputAdornment position='end' onClick={ () => setVisiblePC(!visiblePC)}>
                                                    <img 
                                                        src={ visiblePC ? showPassword : hidePassword}
                                                        alt='eye-password'
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                    />
                                )
                            })
                        }
                        <Box sx={{ py: 2 }}>
                        <LoadingButton
                            fullWidth
                            loadingPosition="start"
                            startIcon={<Send />}
                            variant="outlined"
                            type='submit'
                            loading={ formik.isSubmitting }
                            disabled={ !formik.isValid }
                        >
                            Enviar
                        </LoadingButton>
                        <Button fullWidth variant='outlined' disabled={ formik.isSubmitting } startIcon={<AccountCircle />} onClick={ handleBack } style={{marginTop: '1vh'}}>
                            Login
                        </Button>
                        </Box>
                    </form>
                </Container>
            </Box>
      </>
    )
}
export default ResetPassword;